export const colorsList = {
    mapSectorBorder: '#2d2d30',

    buildingDark: '#191919',
    buildingLight: '#3E3E3E',

    landuseDark: '#1e1e1e',
    landuseLight: '#dadbdf',

    taxiwayDark: '#333333',
    taxiwayLight: '#B4B5B4',

    parkingLight: '#989998',

    taxilineDark: '#494952',
    taxilineLight: '#FFFFFF',

    runwaymarkingDark: '#b0b0b0',
    runwaymarkingLight: '#FFFFFF',

    runwayDark: '#191919',
    runwayLight: '#3E3E3E',

    apronLight: '#D1DDE1',

    lightgray0: '#F7F7FA',
    lightgray50: '#F2F2F7',
    lightgray100: '#EDEDF2',
    lightgray125: '#E6E6EB',
    lightgray150: '#DEDEE7',
    lightgray200: '#D5D5E4',

    darkgray1000: '#131316',
    darkgray950: '#18181B',
    darkgray900: '#202024',
    darkgray875: '#26262C',
    darkgray850: '#2B2B33',
    darkgray800: '#30303C',

    primary300: '#5ca2fd',
    primary400: '#5987FF',
    primary500: '#3B6CEC',
    primary600: '#2052D4',
    primary700: '#174ACF',

    success300: '#05AD5C',
    success400: '#029C59',
    success500: '#008856',
    success600: '#00754E',
    success700: '#006649',

    warning300: '#FCD34D',
    warning400: '#FBBF24',
    warning500: '#F59E0B',
    warning600: '#D97706',
    warning700: '#B45309',

    error300: '#ec804b',
    error400: '#e75e28',
    error500: '#cb421c',
    error600: '#b3341b',
    error700: '#73261b',

    info300: '#A667F9',
    info400: '#984EF9',
    info500: '#8837FB',
    info600: '#7C21FD',
    info700: '#6C0AFF',
};
