// layerHelpers.js
// Provides utility to add Mapbox layers with less boilerplate.

import { colorsList } from './colorsList.js';

/**
 * A thin wrapper around map.addLayer() to reduce boilerplate.
 *
 * @param {object} map - Your Mapbox map instance
 * @param {object} options
 * @param {string} options.id - Unique layer ID
 * @param {string} options.type - fill|line|symbol|etc.
 * @param {string} options.source - GeoJSON source ID (required)
 * @param {array}  [options.filter] - Expression-based filter
 * @param {object} [options.layout={}] - Layout object
 * @param {object} [options.paint={}] - Paint object
 * @param {string} [options.beforeId] - Insert layer before another layer ID
 */
export function addBasicLayer(
    map,
    {
        id,
        type,
        source,
        filter,
        layout = {},
        paint = {},
        beforeId
    }
) {
    if (!source) {
        throw new Error('addBasicLayer: "source" is required.');
    }

    map.addLayer(
        {
            id,
            type,
            source,
            filter,
            layout,
            paint
        },
        beforeId
    );
}

/**
 * Adds up to 3 layers (fill, line, symbol) using the same filter.
 * Good for polygons that need an outline and an optional label.
 *
 * @param {object} map - Mapbox map instance
 * @param {object} options
 * @param {string} options.baseId - Base ID prefix for the fill/line/symbol
 * @param {string} options.source - GeoJSON source ID (required)
 * @param {array}  options.filter - Expression-based filter
 * @param {object} [options.fillPaint] - Fill paint
 * @param {object} [options.linePaint] - Outline paint
 * @param {object} [options.symbolLayout] - Symbol layout (for labeling)
 * @param {object} [options.symbolPaint] - Symbol paint
 * @param {string} [options.beforeId]
 */
export function addPolygonWithOutlineAndLabel(
    map,
    {
        baseId,
        source,
        filter,
        fillPaint,
        linePaint,
        symbolLayout,
        symbolPaint,
        beforeId
    }
) {
    if (!source) {
        throw new Error('addPolygonWithOutlineAndLabel: "source" is required.');
    }

    // 1) Fill layer
    if (fillPaint) {
        addBasicLayer(map, {
            id: `${baseId}-fill`,
            type: 'fill',
            source,
            filter,
            paint: fillPaint,
            beforeId
        });
    }

    // 2) Outline layer
    if (linePaint) {
        addBasicLayer(map, {
            id: `${baseId}-line`,
            type: 'line',
            source,
            filter,
            paint: linePaint,
            beforeId
        });
    }

    // 3) Symbol layer
    if (symbolLayout || symbolPaint) {
        addBasicLayer(map, {
            id: `${baseId}-symbol`,
            type: 'symbol',
            source,
            filter,
            layout: symbolLayout || {},
            paint: symbolPaint || {},
            beforeId
        });
    }
}

/**
 * Updates the paint properties of a single existing layer.
 * If the layer does not exist, does nothing.
 *
 * @param {object} map - The Mapbox map.
 * @param {string} layerId - The layer ID to update.
 * @param {object} paintProps - Key-value of paint properties to set.
 */
export function updateLayerPaint(map, layerId, paintProps) {
    if (!map.getLayer(layerId)) return;
    for (const [propName, propValue] of Object.entries(paintProps)) {
        map.setPaintProperty(layerId, propName, propValue);
    }
}

/**
 * Updates the layout properties of a single existing layer.
 * If the layer does not exist, does nothing.
 *
 * @param {object} map - The Mapbox map.
 * @param {string} layerId - The layer ID to update.
 * @param {object} layoutProps - Key-value of layout properties to set.
 */
export function updateLayerLayout(map, layerId, layoutProps) {
    if (!map.getLayer(layerId)) return;
    for (const [propName, propValue] of Object.entries(layoutProps)) {
        map.setLayoutProperty(layerId, propName, propValue);
    }
}
