// resources/assets/js/AirportDataManager.js
import { addAirportLayers, removeAirportLayers } from './utils/airportLayers.js';

export class AirportDataManager {
    constructor(map, theme, loadRadiusKm = 30) {
        this.map = map;
        this.theme = theme;
        this.loadRadiusKm = loadRadiusKm;

        this.allAirports = [];           // from /api/internal/v1/map/airports/all
        this.loadedAirports = new Set(); // set of ICAOs currently on the map
        this.airportDataCache = {};      // icao -> amdbData
        this.pendingLoads = new Set();   // Track ICAOs currently being loaded

        this.handleMapMoveEnd = this.handleMapMoveEnd.bind(this);
    }

    async init() {
        const resp = await fetch('/api/internal/v1/map/airports/all');
        this.allAirports = await resp.json();
        // e.g. [ { icao: 'EGSS', latitude: 51.8769, longitude: -0.3532 }, ...]
        this.map.on('moveend', this.handleMapMoveEnd);
        this.handleMapMoveEnd(); // initial check
    }

    handleMapMoveEnd() {
        const zoom = this.map.getZoom();
        if (zoom < 12) {
            // Remove all loaded airport layers.
            this.loadedAirports.forEach((icao) => {
                removeAirportLayers(this.map, icao);
            });
            this.loadedAirports.clear();
            return;
        }
        const center = this.map.getCenter(); // { lng, lat }
        const inRangeAirports = this.allAirports.filter((airport) =>
            isWithinRadius(center.lat, center.lng, airport.latitude, airport.longitude, this.loadRadiusKm)
        );
        // Load newly in-range airports.
        inRangeAirports.forEach((airport) => {
            if (!this.loadedAirports.has(airport.icao)) {
                this.loadAirport(airport.icao);
            }
        });
        // Remove airports that are now out-of-range.
        this.loadedAirports.forEach((icao) => {
            const stillInRange = inRangeAirports.some(a => a.icao === icao);
            if (!stillInRange) {
                removeAirportLayers(this.map, icao);
                this.loadedAirports.delete(icao);
            }
        });
    }

    async loadAirport(icao) {
        if (this.pendingLoads.has(icao)) {
            return;
        }
        this.pendingLoads.add(icao);

        try {
            let amdbData = this.airportDataCache[icao];
            if (!amdbData) {
                const resp = await fetch(`/api/internal/v1/map/airports/data/${icao}`);
                if (!resp.ok) {
                    this.allAirports = this.allAirports.filter(airport => airport.icao !== icao);
                    console.warn(`Removed airport ${icao} due to failed fetch.`);
                    return;
                }
                amdbData = await resp.json();
                this.airportDataCache[icao] = amdbData;
            }

            const center = this.map.getCenter();
            const airport = this.allAirports.find((a) => a.icao === icao);
            if (airport && isWithinRadius(center.lat, center.lng, airport.latitude, airport.longitude, this.loadRadiusKm)) {
                // Add airport layers.
                addAirportLayers(this.map, icao, amdbData, this.theme);
                this.loadedAirports.add(icao);
                // Reposition airport layers to be immediately below marker layers.
                this.repositionAirportLayers();
            } else {
                removeAirportLayers(this.map, icao);
            }
        } catch (error) {
            console.error(`Failed to load airport data for ${icao}:`, error);
        } finally {
            this.pendingLoads.delete(icao);
        }
    }

    /**
     * Moves all airport layers (IDs starting with "airport-") so they appear immediately below a marker layer.
     */
    repositionAirportLayers() {
        const allLayers = this.map.getStyle().layers;
        // Define your custom layer IDs that should appear above airport layers.
        const customLayerIds = [
            'pilot-flight-path-layer',
            'polyline-segments-layer',
            'flight-path-layer'
        ];

        // Find the first layer among all layers that is in the custom group.
        const firstCustomLayer = allLayers.find(layer =>
            customLayerIds.includes(layer.id)
        );

        // Determine the layer id to insert before. If none, airport layers will be on top.
        const beforeId = firstCustomLayer ? firstCustomLayer.id : undefined;

        // For every layer with id starting with "airport-", move it just before the custom layers.
        allLayers.forEach(layer => {
            if (layer.id.startsWith('airport-')) {
                this.map.moveLayer(layer.id, beforeId);
            }
        });
    }




    /**
     * Re-adds airport layers on theme change.
     */
    onThemeChange(newTheme) {
        this.theme = newTheme;
        this.loadedAirports.forEach((icao) => {
            const amdbData = this.airportDataCache[icao];
            if (!amdbData) return;
            addAirportLayers(this.map, icao, amdbData, newTheme);
        });
        // After re-adding, reposition them below marker layers.
        this.repositionAirportLayers();
    }
}

function isWithinRadius(lat1, lon1, lat2, lon2, radiusKm) {
    const R = 6371;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) ** 2 +
        Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c <= radiusKm;
}
function deg2rad(d) { return d * (Math.PI / 180); }
