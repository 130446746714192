// airportLayers.js
// A single file that can fully render layers for one airport
// with the same loading order & style definitions as your old initMapLayers.
// We create: "airport-<ICAO>-source" and layers like "airport-<ICAO>-apronelement-fill", etc.

import { addBasicLayer, addPolygonWithOutlineAndLabel } from './layerHelpers.js';
import { colorsList } from './colorsList.js';

/**
 * Removes all layers associated with a single airport (by ICAO),
 * then removes the unique source for that airport.
 */
export function removeAirportLayers(map, icao) {
    const sourceId = `airport-${icao}-source`;
    const style = map.getStyle();
    if (!style || !style.layers) return;

    // Find all layers whose ID starts with `airport-{icao}-`
    const layerIds = style.layers
        .map((l) => l.id)
        .filter((id) => id.startsWith(`airport-${icao}-`));

    // Remove those layers
    layerIds.forEach((layerId) => {
        if (map.getLayer(layerId)) {
            map.removeLayer(layerId);
        }
    });

    // Remove the source
    if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
    }
}

/**
 * Render (or re-render) all layers for one airport with the specified theme.
 * This function is the single source of truth for your styling order/paint logic.
 *
 * @param {mapboxgl.Map} map   - The Mapbox instance
 * @param {string} icao       - e.g. "EGSS"
 * @param {object} amdbData   - The FeatureCollection for this airport
 * @param {string} theme      - "dark" or "light"
 */
export function addAirportLayers(map, icao, amdbData, theme) {
    const sourceId = `airport-${icao}-source`;

    // 1) Remove existing layers if any (in case we're re-rendering or theme changed)
    removeAirportLayers(map, icao);

    // 2) Add the unique source for this airport
    map.addSource(sourceId, {
        type: 'geojson',
        data: amdbData,
        lineMetrics: true
    });

    // 3) We'll define an array of style definitions that match your original "initMapLayers" order.
    //    Except we reference "airport-{icao}-..." for each layer ID/baseId,
    //    and "source: sourceId" for each layer.

    const filterType = (val) => ['==', ['get', 'layerType'], val];

    // Example expressions used for taxiway lines, from your code:
    const taxiwayLineColor = [
        'case',
        ['==', ['get', 'style'], 1],
        theme === 'dark' ? colorsList.taxilineDark : colorsList.taxilineLight,
        ['==', ['get', 'color'], 1],
        theme === 'dark' ? colorsList.error500 : colorsList.error300,
        ['==', ['get', 'color'], 2],
        theme === 'dark' ? colorsList.primary500 : colorsList.primary300,
        theme === 'dark' ? colorsList.taxilineDark : colorsList.taxilineLight
    ];

    const taxiwayLineOpacity = [
        'case',
        ['==', ['get', 'style'], 1],
        1,
        ['==', ['get', 'color'], 1],
        0.3,
        ['==', ['get', 'color'], 2],
        0.3,
        1
    ];

    const taxiwayLineWidth = [
        'case',
        ['==', ['get', 'style'], 1],
        1,
        2
    ];

    const taxiwayLineDash = [
        'case',
        ['==', ['get', 'style'], 1],
        ['literal', [4, 4]],
        ['literal', []]
    ];

    // A big array of layer definitions, preserving the EXACT ORDER from your initMapLayers
    // (We've replaced baseId/id with `airport-${icao}-...` so they’re unique.)
    const layerDefs = [
        // 1) apronelement
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-apronelement`,
            source: sourceId,
            filter: filterType('apronelement'),
            fillPaint: {
                'fill-color': theme === 'dark'
                    ? colorsList.buildingDark
                    : colorsList.taxiwayLight,
                'fill-opacity': 1
            }
        },

        // 2) vertical line structure
        {
            addMethod: 'basic',
            id: `airport-${icao}-verticallinestructure-line`,
            type: 'line',
            source: sourceId,
            filter: filterType('verticallinestructure'),
            paint: {
                'line-color': colorsList.darkgray950,
                'line-opacity': 1
            }
        },

        // 3) vertical polygonal structure
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-verticalpolygonalstructure`,
            source: sourceId,
            filter: filterType('verticalpolygonalstructure'),
            fillPaint: {
                'fill-color': [
                    'case',
                    ['>', ['get', 'plysttyp'], 4],
                    '#000000',
                    ['==', ['get', 'plysttyp'], 1],
                    theme === 'dark' ? colorsList.buildingDark : colorsList.buildingLight,
                    theme === 'dark' ? colorsList.buildingDark : colorsList.buildingLight
                ],
                'fill-opacity': [
                    'case',
                    ['>', ['get', 'plysttyp'], 4],
                    0,
                    ['==', ['get', 'plysttyp'], 1],
                    1,
                    0.4
                ]
            },
            linePaint: {
                'line-color': [
                    'case',
                    ['>', ['get', 'plysttyp'], 4],
                    '#000000',
                    ['==', ['get', 'plysttyp'], 1],
                    theme === 'dark'
                        ? colorsList.buildingLight
                        : colorsList.buildingDark,
                    theme === 'dark'
                        ? colorsList.buildingDark
                        : colorsList.buildingLight
                ],
                'line-opacity': [
                    'case',
                    ['>', ['get', 'plysttyp'], 4],
                    0,
                    ['==', ['get', 'plysttyp'], 1],
                    0.1,
                    0.5
                ],
                'line-width': 2
            },
            symbolLayout: {
                'text-field': ['case', ['has', 'ident'], ['get', 'ident'], ''],
                'text-font': ['Noto Sans Regular'],
                'text-size': [
                    'case',
                    ['==', ['get', 'plysttyp'], 1],
                    12,
                    10
                ],
                'symbol-placement': 'point',
                'text-anchor': 'center',
                'text-justify': 'center',
                'text-allow-overlap': false,
                'text-ignore-placement': false
            },
            symbolPaint: {
                'text-color': theme === 'dark'
                    ? colorsList.lightgray200
                    : colorsList.lightgray50,
                'text-opacity': theme === 'dark'
                    ? 0.5
                    : 1,
            }
        },

        // 4) taxiwayelement
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-taxiwayelement`,
            source: sourceId,
            filter: filterType('taxiwayelement'),
            fillPaint: {
                'fill-color': theme === 'dark'
                    ? colorsList.taxiwayDark
                    : colorsList.taxiwayLight,
                'fill-opacity': 1
            }
        },

        // 5) defaultZIndexLayers
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-parkingstandlocation`,
            source: sourceId,
            filter: filterType('parkingstandlocation'),
            fillPaint: {
                'fill-color': colorsList.primary500,
                'fill-opacity': 1
            }
        },
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-frequencyarea`,
            source: sourceId,
            filter: filterType('frequencyarea'),
            fillPaint: {
                'fill-color': theme === 'dark'
                    ? colorsList.darkgray875
                    : colorsList.darkgray850,
                'fill-opacity': 1
            }
        },
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-runwayelement`,
            source: sourceId,
            filter: filterType('runwayelement'),
            fillPaint: {
                'fill-color': theme === 'dark'
                    ? colorsList.runwayDark
                    : colorsList.runwayLight,
                'fill-opacity': 1
            }
        },
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-runwaydisplacedarea`,
            source: sourceId,
            filter: filterType('runwaydisplacedarea'),
            fillPaint: {
                'fill-color': theme === 'dark'
                    ? colorsList.runwayDark
                    : colorsList.runwayLight,
                'fill-opacity': 1
            }
        },
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-runwayintersection`,
            source: sourceId,
            filter: filterType('runwayintersection'),
            fillPaint: {
                'fill-color': theme === 'dark'
                    ? colorsList.runwayDark
                    : colorsList.runwayLight,
                'fill-opacity': 1
            }
        },
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-runwaymarking`,
            source: sourceId,
            filter: filterType('runwaymarking'),
            fillPaint: {
                'fill-color': theme === 'dark'
                    ? colorsList.runwaymarkingDark
                    : colorsList.runwaymarkingLight,
                'fill-opacity': 1
            }
        },
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-runwayshoulder`,
            source: sourceId,
            filter: filterType('runwayshoulder'),
            fillPaint: {
                'fill-color': theme === 'dark'
                    ? colorsList.darkgray950
                    : colorsList.taxiwayLight,
                'fill-opacity': 1
            }
        },
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-taxiwayshoulder`,
            source: sourceId,
            filter: filterType('taxiwayshoulder'),
            fillPaint: {
                'fill-color': theme === 'dark'
                    ? colorsList.darkgray950
                    : colorsList.taxiwayLight,
                'fill-opacity': 1
            }
        },
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-arrestinggearlocation`,
            source: sourceId,
            filter: filterType('arrestinggearlocation'),
            fillPaint: {
                'fill-color': colorsList.info500,
                'fill-opacity': 1
            }
        },

        // 6) Helipad / FATO
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-helipadthreshold`,
            source: sourceId,
            filter: filterType('helipadthreshold'),
            fillPaint: {
                'fill-color': colorsList.success500,
                'fill-opacity': 0.1
            },
            linePaint: {
                'line-color': colorsList.success500,
                'line-opacity': 0.1
            },
            symbolLayout: {
                'text-field': 'H',
                'text-font': ['Noto Sans Regular'],
                'text-size': 14
            },
            symbolPaint: {
                'text-color': colorsList.success500,
                'text-opacity': 1
            }
        },
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-finalapproachandtakeoffarea`,
            source: sourceId,
            filter: filterType('finalapproachandtakeoffarea'),
            fillPaint: {
                'fill-color': colorsList.success500,
                'fill-opacity': 0.1
            },
            linePaint: {
                'line-color': colorsList.success500,
                'line-opacity': 0.1
            },
            symbolLayout: {
                'text-field': ['get', 'idrwy'],
                'text-font': ['Noto Sans Regular'],
                'text-size': 14
            },
            symbolPaint: {
                'text-color': colorsList.success500,
                'text-opacity': 1
            }
        },

        // 7) Taxiway intersection marking
        {
            addMethod: 'basic',
            id: `airport-${icao}-taxiwayintersectionmarking-line`,
            source: sourceId,
            type: 'line',
            filter: filterType('taxiwayintersectionmarking'),
            paint: {
                'line-color': colorsList.lightgray200,
                'line-opacity': 0.4,
                'line-dasharray': [4, 6]
            }
        },
        {
            addMethod: 'basic',
            id: `airport-${icao}-taxiwayintersectionmarking-symbol`,
            source: sourceId,
            type: 'symbol',
            filter: filterType('taxiwayintersectionmarking'),
            layout: {
                'text-field': ['case', ['has', 'idlin'], ['get', 'idlin'], ''],
                'text-font': ['Noto Sans Regular'],
                'text-size': 10,
                'text-offset': [0, 0.5],
                'symbol-placement': 'line'
            },
            paint: {
                'text-color': colorsList.lightgray200,
                'text-opacity': 0.6
            }
        },

        // 8) Parking stand area
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-parkingstandarea`,
            source: sourceId,
            filter: filterType('parkingstandarea'),
            fillPaint: {
                'fill-color': theme === 'dark'
                    ? colorsList.landuseDark
                    : colorsList.parkingLight,
                'fill-opacity': 1
            },
            linePaint: {
                'line-color': colorsList.lightgray200,
                'line-opacity': 0.1
            }
        },
        {
            addMethod: 'basic',
            id: `airport-${icao}-standguidanceline-line`,
            source: sourceId,
            type: 'line',
            filter: filterType('standguidanceline'),
            paint: {
                'line-color': theme === 'dark'
                    ? colorsList.taxilineDark
                    : colorsList.taxilineLight,
                'line-opacity': 1
            }
        },
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-blastpad`,
            source: sourceId,
            filter: filterType('blastpad'),
            fillPaint: {
                'fill-color': theme === 'dark'
                    ? colorsList.darkgray1000
                    : colorsList.darkgray800,
                'fill-opacity': 0.5
            }
        },
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-constructionarea`,
            source: sourceId,
            filter: filterType('constructionarea'),
            fillPaint: {
                'fill-color': colorsList.error500,
                'fill-opacity': 0.1
            }
        },
        {
            addMethod: 'basic',
            id: `airport-${icao}-gatedata-symbol`,
            source: sourceId,
            type: 'symbol',
            filter: [
                'all',
                ['==', ['get', 'layerType'], 'gatedata'],
                ['has', 'name']
            ],
            layout: {
                'text-field': ['coalesce', ['get', 'name'], ''],
                'text-font': ['Montserrat Bold'],
                'text-size': 12,
                'text-anchor': 'center',
                'text-justify': 'center'
            },
            paint: {
                'text-color': theme === 'dark'
                    ? colorsList.lightgray200
                    : colorsList.taxilineLight,
                'text-padding': 8,
                'text-opacity': 1,
                'text-halo-color': theme === 'dark'
                    ? colorsList.landuseDark
                    : colorsList.parkingLight,
                'text-halo-width': 4,
                'text-halo-blur': 0
            }
        },

        // 9) Taxiway guidance lines
        {
            addMethod: 'basic',
            id: `airport-${icao}-taxiwayguidanceline-line`,
            source: sourceId,
            type: 'line',
            filter: filterType('taxiwayguidanceline'),
            paint: {
                'line-color': taxiwayLineColor,
                'line-opacity': taxiwayLineOpacity,
                'line-width': taxiwayLineWidth,
                'line-dasharray': taxiwayLineDash
            }
        },
        {
            addMethod: 'basic',
            id: `airport-${icao}-taxiwayguidanceline-symbol`,
            source: sourceId,
            type: 'symbol',
            filter: [
                'all',
                ['==', ['get', 'layerType'], 'taxiwayguidanceline'],
                ['has', 'idlin']
            ],
            layout: {
                'text-field': ['coalesce', ['get', 'idlin'], ''],
                'text-font': ['Montserrat Bold'],
                'text-size': 12,
                'symbol-placement': 'line',
                'text-anchor': 'center',
                'text-offset': [0, 0],
                'text-allow-overlap': false,
                'text-ignore-placement': false
            },
            paint: {
                'text-color': [
                    'case',
                    // style=1 => white text
                    ['==', ['get', 'style'], 1],
                    theme === 'dark'
                        ? colorsList.lightgray200
                        : colorsList.taxilineLight,
                    // color=1 => orange
                    ['==', ['get', 'color'], 1],
                    theme === 'dark'
                        ? colorsList.error300
                        : colorsList.error500,
                    // color=2 => blue
                    ['==', ['get', 'color'], 2],
                    theme === 'dark'
                        ? colorsList.primary300
                        : colorsList.primary500,
                    // default => lightgray200
                    theme === 'dark'
                        ? colorsList.lightgray200
                        : colorsList.taxilineLight
                ],
                'text-padding': 8,
                'text-opacity': 1,
                'text-halo-color': theme === 'dark'
                    ? colorsList.taxiwayDark
                    : colorsList.taxiwayLight,
                'text-halo-width': 4,
                'text-halo-blur': 0
            }
        },

        // 10) deicingarea
        {
            addMethod: 'polygon',
            baseId: `airport-${icao}-deicingarea`,
            source: sourceId,
            filter: filterType('deicingarea'),
            fillPaint: {
                'fill-color': theme === 'dark'
                    ? colorsList.primary400
                    : colorsList.primary300,
                'fill-opacity': 0.1
            },
            symbolLayout: {
                'text-field': [
                    'case',
                    ['has', 'ident'],
                    ['get', 'ident'],
                    ''
                ],
                'text-font': ['Noto Sans Regular'],
                'text-size': 12
            },
            symbolPaint: {
                'text-color': theme === 'dark'
                    ? colorsList.primary400
                    : colorsList.primary300,
                'text-opacity': 1
            }
        },

        // 11) runwayexitline-line
        {
            addMethod: 'basic',
            id: `airport-${icao}-runwayexitline-line`,
            source: sourceId,
            type: 'line',
            filter: filterType('runwayexitline'),
            paint: {
                'line-color': theme === 'dark'
                    ? colorsList.taxilineDark
                    : colorsList.taxilineLight,
                'line-opacity': 1,
                'line-width': 2
            }
        },

        // 12) taxiwayholdingposition
        {
            addMethod: 'basic',
            id: `airport-${icao}-taxiwayholdingposition-line`,
            source: sourceId,
            type: 'line',
            filter: filterType('taxiwayholdingposition'),
            layout: {
                'line-cap': 'round',
                'line-join': 'round'
            },
            paint: {
                'line-color': [
                    'case',
                    ['==', ['get', 'catstop'], 2],
                    colorsList.error500,
                    colorsList.warning700
                ],
                'line-opacity': 0.8,
                'line-width': 2,
            }
        },
        {
            addMethod: 'basic',
            id: `airport-${icao}-taxiwayholdingposition-symbol`,
            source: sourceId,
            type: 'symbol',
            filter: filterType('taxiwayholdingposition'),
            layout: {
                'text-field': [
                    'case',
                    ['has', 'idlin'],
                    ['get', 'idlin'],
                    ''
                ],
                'text-font': ['Montserrat Bold'],
                'text-size': 12,
                'symbol-placement': 'line-center',
                'text-rotation-alignment': 'map',
                'text-anchor': 'center',
                'text-offset': [0, 0],
                'text-allow-overlap': false,
                'text-ignore-placement': false
            },
            paint: {
                'text-color': [
                    'case',
                    ['==', ['get', 'catstop'], 2],
                    colorsList.error500,
                    colorsList.warning700
                ],
                'text-opacity': 1,
                'text-padding': 8,
                'text-halo-color': theme === 'dark'
                    ? colorsList.taxiwayDark
                    : colorsList.taxiwayLight,
                'text-halo-width': 4,
                'text-halo-blur': 0
            }
        }
    ];

    // 4) Finally, iterate those definitions in the same order, adding them to the map.
    layerDefs.forEach((def) => {
        if (def.addMethod === 'basic') {
            addBasicLayer(map, {
                id: def.id,
                type: def.type,
                source: def.source,
                filter: def.filter,
                layout: def.layout || {},
                paint: def.paint || {},
                beforeId: def.beforeId || null,
            });
        } else if (def.addMethod === 'polygon') {
            addPolygonWithOutlineAndLabel(map, {
                baseId: def.baseId,
                source: def.source,
                filter: def.filter,
                fillPaint: def.fillPaint || null,
                linePaint: def.linePaint || null,
                symbolLayout: def.symbolLayout || null,
                symbolPaint: def.symbolPaint || null,
                beforeId: def.beforeId || null,
            });
        }
    });
}
